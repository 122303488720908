import { loginCall, logoutCall } from '../api/auth';

export default {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    const { sessionId } = await loginCall(username, password);

    localStorage.setItem('sessionId', sessionId);
  },
  // called when the user clicks on the logout button
  logout: async () => {
    // console.warn('======== logout');
    if (localStorage.getItem('sessionId')) {
      await logoutCall(localStorage.getItem('sessionId'));
    }

    localStorage.removeItem('sessionId');
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    // console.warn('======== checkError');
    if (status === 401 || status === 403) {
      localStorage.removeItem('sessionId');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    // console.warn('======== checkAuth', localStorage.getItem('sessionId'));
    return localStorage.getItem('sessionId')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    // console.warn('======== getPermissions');
    return Promise.resolve()
  },
};
