import { stringify } from 'query-string';

import { METHODS, adminRequest } from './core';

export const bookCount = async() => {
  const path = `/books/count`;

  const resp = await adminRequest(METHODS.GET, path);

  if (!resp.ok) {
    return Promise.reject({
      status: resp.status,
      message: await resp.text(),
    });
  }

  return +(await resp.json());
};

export const bookList = async ({ offset, limit }) => {
  const path = `/books/some?${stringify({ offset, amount: limit })}`;

  const resp = await adminRequest(METHODS.GET, path);

  if (!resp.ok) {
    return Promise.reject({
      status: resp.status,
      message: await resp.text(),
    });
  }

  const data = await resp.json();

  return data;
};
