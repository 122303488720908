const SCHWARZ_BASE_URL = 'https://immense-wave-2735.herokuapp.com';
const ADMIN_BASE_URL = 'https://boksnok-admin.herokuapp.com';

const mergeQS = (path, qs) => `${path}${path.includes('?') ? '&' : '?'}${qs}`;

/**
 * The accepted HTTP request values.
 * @enum {string}
 */
export const METHODS = {
  DELETE: 'DELETE',
  GET: 'GET',
  POST: 'POST',
  PUT: 'POST',
};

/** Make a 'raw' API call - all config goes in here
 *
 * @param {METHODS} method         The HTTP method to be used
 * @param {string}  url            The full URL to the requested API call
 * @param {object} params          Optional additional data for the API call
 * @param {object} params.data     HTTP body data to be passed to the API
 * @param {object} params.headers  HTTP headers in here
 *
 * @return {Promise<any>}
 */
export const request = async (method, url, { data, headers } = {}) => {
  if ([METHODS.POST, METHODS.PUT].includes(method) && !data) {
    throw new Error(
      `request() with '${method}' requires data included in params`
    );
  }

  const response = await fetch(url, {
    // Default options are marked with *
    method,
    // mode: 'no-cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json charset=UTF-8',
      ...headers,
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    ...{ ...(data ? { body: JSON.stringify(data) } : {}) },
  });
  return response;
};

export const schwarzRequest = (method, path, params) =>
  request(method, `${SCHWARZ_BASE_URL}${path}`, params);

export const adminRequest = (method, path, params) =>
  request(
    method,
    mergeQS(
      `${ADMIN_BASE_URL}${path}`,
      `sessionId=${localStorage.getItem('sessionId')}`
    ),
    params
  );
