import { METHODS, schwarzRequest } from './core';

export const loginCall = async (username, password) => {
  const path = '/admin/login';
  const data = { username, password };

  const resp = await schwarzRequest(METHODS.POST, path, { data });

  if (!resp.ok) {
    return Promise.reject({
      status: resp.status,
      message: await resp.text(),
    });
  }

  return {
    username,
    sessionId: await resp.text(),
  };
};

export const logoutCall = async (sessionId) => {
  const path = `/admin/logout/${sessionId}}`;
  await schwarzRequest(METHODS.GET, path);

  return true;
};
