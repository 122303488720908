import React from 'react';
import {
  BooleanField,
  Datagrid,
  ImageField,
  List,
  TextField,
} from 'react-admin';

/*
title: "Starkast i klassen"
publisher: "Bonnier Carlsen"
publisher_cut: 0.75
author: "Helena Bross"
is_published: "true"
url: ""
pdf: ""
frontcover: "http://glacial-ocean-3549.herokuapp.com/page/316dc4dc-fa80-4e94-b022-1e76033523b9"
frontcover_small: "http://glacial-ocean-3549.herokuapp.com/page/45102097-352c-4684-9a88-ece1f651f212"
frontcover_original: "http://glacial-ocean-3549.herokuapp.com/page/296a4fc5-34a5-45d2-9ca9-100b72b99cd0"
frontcover_width: 875
frontcover_height: 1241
frontcover_thumbnail: "http://glacial-ocean-3549.herokuapp.com/page/ee59ab05-2f82-4c17-85be-3aaab45deaa6"
backcover_original: "http://glacial-ocean-3549.herokuapp.com/page/0935a853-79b4-410a-8c5a-ef3b62270784"
backcover_small: "http://glacial-ocean-3549.herokuapp.com/page/5e72e23a-5f24-4993-88a2-c6bc4c8810b3"
backcover: "http://glacial-ocean-3549.herokuapp.com/page/a79b777b-cdc6-4cd5-a868-89684764aeb5"
backcover_thumbnail: "http://glacial-ocean-3549.herokuapp.com/page/63010320-4818-42f5-89d1-564cce473f6c"
illustrator: "Christel Rönns"
layout: "Malin Westman"
read_length: "62"
recitation: "false"
animations: "false"
summary: "Edvin letar i ryggsäcken efter sitt äpple. Han provar det lilla facket där nere, men det är tomt.↵Då tittar han i sidofacket. Det är också tomt. Han öppnar det stora facket. Det är alldeles svar där nere. Edvin kan inte se något äpple. Han letar och letar..."
orientation: ""
currentNrPages: 62
page_size: 800
cover_thumbnail_size: 300
jpg_quality: 100
generated_at: 1454281364309
is_synced: true
 */

export const BookList = (props) => (
  <List {...props}>
    <Datagrid>
      <ImageField source="frontcover_small"/>
      <TextField source="title" />
      <TextField source="publisher" />
      <TextField source="is_published" />
      <TextField source="id" />
      {/*<EditButton />*/}
    </Datagrid>
  </List>
);
