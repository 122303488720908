// in src/App.js
import React from 'react';
import { Admin, Resource } from 'react-admin';

import authProvider from './utils/providers/authProvider';
import dataProvider from './utils/providers/dataProvider';
import Dashboard from './Dashboard';
import { BookList } from './components/books';

import BookIcon from '@material-ui/icons/Book';

const App = () => (
  <Admin authProvider={authProvider} dashboard={Dashboard} dataProvider={dataProvider}>
    <Resource name="books" icon={BookIcon} list={BookList} />
  </Admin>
);

export default App;
