import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

export default () => (
  <Card>
    <CardHeader title="Det här är Story-min - Boksnoks framtida admin-system" />
    <CardContent>Vad skulle du vilja ha här?</CardContent>
  </Card>
);

